/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




.marker-cluster-small {
    background-color: #00008b;
  }

  .marker-cluster-small div {
    background-color: #00008b;
    color: white;
    font-weight: bold;
  }

  col-row {
    width: 150px;
  }

  .card-content-md {
    line-height: 1;
    padding: 0%;
  }

  :host {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .sc-ion-card-md-h {
    margin-inline-start: 0%;
    margin-inline-end: 0%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .ion-grid {
    padding: 0%;
  }

  // .sc-ion-popover-md-h {
  //   --width: 350px ;
  //     --height:auto ;
  //     --position: center ;
  // }

  .notifPopover {
      --width: 90% ;
    --height:auto ;
    --position: center ;
  }

  .historiquePopovers{
    --width: 80% ;
  }

  .addPoiPopover{
    --width: 90% ;
  }

  // .greenPopup .leaflet-popup-content-wrapper,
  // .greenPopup .leaflet-popup-tip {
  // 	background-color:#95f744 ;
  // }

  // .redPopup .leaflet-popup-content-wrapper,
  // .redPopup .leaflet-popup-tip {
  // 	background-color:#FD2751 ;
  //   color: white;
  // }

  // .bluePopup .leaflet-popup-content-wrapper,
  // .bluePopup .leaflet-popup-tip {
  // 	background-color:#54D0ED ;
  // }

  // .issuePopup .leaflet-popup-content-wrapper,
  // .issuePopup .leaflet-popup-tip {
  // 	background-color:#8e8e8e ;
  // }

  // .bluePopup .leaflet-popup-content{
  //   width: 300px;
  // }
  // .redPopup .leaflet-popup-content{
  //   width: 300px;
  // }
  // .greenPopup .leaflet-popup-content{
  //   width: 300px;
  // }
  // .issuePopup .leaflet-popup-content{
  //   width: 300px;
  // }

  // .realTimePopup .leaflet-popup-tip
  .realTimePopup .leaflet-popup-content-wrapper {
      background-color:white ;
    color: rgb(36, 36, 36);
    border-radius: 7px;
  }

  .realTimePopup .leaflet-popup-content{
      width: 220px;
      //max-height: 300px;
      margin: 10px;
  }

  .marcheArret{
    --width: 70% ;
    --height:auto ;
    --position: center ;
  }

  .details{
    --width: 50% ;
    --height:auto ;
    --position: center ;
  }

  .leaflet-draw-toolbar a {
    background-image: url(assets/img/map-marker.png);
  }


  .leaflet-draw-actions{
    background-color: white;
    list-style: none;
    padding: 0!important;
    margin:0;
    border: 3px;
    border-radius: 12px;
    border: none;
    outline-offset: 4px;
  }

  .leaflet-draw-actions a {
    color: black !important;
    font-size: small;
    font-weight: bold;
    margin: 1px !important;
    text-decoration: none !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

  }


.leaflet-draw-toolbar {
   background-color: white!important;
  padding: 3px;
  }
